<template>
	<div class="homepage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- banner -->
			<div class="banner_nav_bg">
				<img src="../assets/img/bg1.jpg" alt="" />
				
				<div class="banner_title_wrap">
					<div class="banner_title_center">
						<p>Deeply cultivated in the field of automotive components for over 15 years</p>
						<div class="BannerSubTitle"><span>20,000+ SKU </span><span>High-quality</span></div>
					</div>
				</div>
			</div>
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img style="width: 80px;" src="../assets/img/productImg/logoPic.png" alt="" />
						<img src="../assets/img/productImg/logoContent.png" alt="" />
					</div>

					<ul class="navWrap_center_ul">
						<li
							v-for="(item, index) in navList"
							:key="index"
							@click="funyuming(item.url,index)"
							:class="index===activeIndex?'activeLi':''"
						>
							{{ item.title }}
						</li>
						<li>
							<el-input v-model="searchValue" placeholder="请输入产品名称"  @keyup.enter.native="keyUpSearch"></el-input>
						</li>
					</ul>

				</div>
			</div>
			<!-- title -->
		</div>
		<!-- 公司简介 -->
		<div class="companyIntroduce">
			<div class="top">
				<p style="font-size: 40px;">ABOUT US</p>
			</div>
			<div class="company">
				<div class="imgshow">
					<img style="height: 300px;width: 470px;" :src="imgSrc" alt="">
				</div>
				<div class="companyText">
					<div v-html="companyEditor"></div>
				</div>
			</div>
		</div>
		<!-- 公司环境 -->
		<div class="GroupIndustry">
			<div class="top">
				<p style="font-size: 40px;">WORKSHOP</p>
			</div>
			<div class="environment">
				<div class="leftImg">
					<div class="bigImg">
						<img src="../assets/img/productImg/WORKSHOP1.jpg" alt="" />
					</div>
						<div class="smallImg">
							<div style="display: flex;">
								<img src="../assets/img/productImg/WORKSHOP2.jpg" alt="" />
								<img src="../assets/img/productImg/WORKSHOP3.jpg" alt="" />
							</div>
							<div style="display: flex;">
								<img src="../assets/img/productImg/WORKSHOP4.jpg" alt="" />
								<img src="../assets/img/productImg/WORKSHOP5.jpg" alt="" />
							</div>
						</div>
					<!-- <div style="overflow-x: auto; width: calc(100% - 10px);margin-right: 10px;" >
						<div class="smallImg">
							<img src="../assets/img/productImg/WORKSHOP1.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP2.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP3.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP4.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP5.jpg" alt="" />
						</div>
						<div class="smallImg">
							<img src="../assets/img/productImg/WORKSHOP1.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP2.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP3.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP4.jpg" alt="" />
							<img src="../assets/img/productImg/WORKSHOP5.jpg" alt="" />
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<!-- 公司物流 -->
		<div class="GroupIndustry">
			<div class="top">
				<p style="font-size: 40px;">WAREHOUSE</p>
			</div>
			<div class="environment">
				<div class="leftImg">
					<div class="bigImg">
						<img src="../assets/img/productImg/WAREHOUSE1.jpg" alt="" />
					</div>
					<div class="smallImg">
						<div style="display: flex;">
							<img src="../assets/img/productImg/WAREHOUSE2.jpg" alt="" />
							<img src="../assets/img/productImg/WAREHOUSE3.jpg" alt="" />
						</div>
						<div style="display: flex;">
							<img src="../assets/img/productImg/WAREHOUSE4.jpg" alt="" />
							<img src="../assets/img/productImg/WAREHOUSE5.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 公司仓储 -->
		<div class="GroupIndustry">
			<div class="top">
				<p style="font-size: 40px;">SHIPMENT</p>
			</div>
			<div class="environment">
				<div class="leftImg">
					<div class="bigImg">
						<img src="../assets/img/productImg/SHIPMENT1.jpg" alt="" />
					</div>
					<div class="smallImg">
						<div style="display: flex;">
							<img src="../assets/img/productImg/SHIPMENT2.jpg" alt="" />
							<img src="../assets/img/productImg/SHIPMENT3.jpg" alt="" />
						</div>
						
						<div style="display: flex;">
							<img src="../assets/img/productImg/SHIPMENT4.jpg" alt="" />
							<img src="../assets/img/productImg/SHIPMENT5.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 我的产品 -->
		<div class="GroupIndustry">
			<div class="top">
				<p style="font-size: 40px;">PRODUCTS</p>
				<p @click="toProduct" style="font-size: 20px;color: #638f4e;cursor: pointer;">更多></p>
			</div>
			<div class="GroupIndustry_cont">
				<ul style="width:100%;display: flex;justify-content: space-around;">
					<li class="ProductLiTitle" @click="changeProduct(index,item.id)" :class="index===ProductActiveIndex?'ProductLiTitleActive':''" v-for="(item,index) in productList" :key="index">{{ item.moduleValue }}</li>
				</ul>
				<div class="productContent" v-if="productImgList.length">
					<div class="productImgHover" @mouseover="item.showHover=true" @mouseleave="item.showHover=false"  v-for="(item,index) in productImgList" :key="index">
						<img class="productImgClass" :src="`http://116.198.202.143:10086/picture/download/${item.imgName}`" alt="">
						<!-- <img class="productImgClass" :src="item.url" alt=""> -->
						<div v-show="item.showHover" class="hoverImgContent" @click="toProduct()">
							<span>{{ item.title }}</span>
							查看更多
						</div>
					</div>
				</div>
				<div v-else class="productContent" style="justify-content: center">
					暂无数据
				</div>
			</div>
		</div>
		<!-- 证书资质 -->
		<div class="GroupIndustry">
			<div class="top">
				<p style="font-size: 40px;">CERTIFICATES</p>
			</div>
			<div class="certificate">
				<img src="../assets/img/productImg/certificate1.jpeg" alt="" />
				<img src="../assets/img/productImg/certificate2.jpeg" alt="" />
				<img src="../assets/img/productImg/certificate3.jpg" alt="" />
			</div>
		</div>
		<!-- footer -->
		<footer2></footer2>

		<img v-show="showBackUp" class="showBackUp" @click="backToTop" src="../assets/img/productImg/backUp.jpg" alt="" />
	</div>
</template>

<script>
import footer2 from "../components/footer.vue";
import { getPage,getDictKeyValue, getDictById } from "@/api/index";
export default {
	name: "index",
	components: {
		footer2,
	},
	data() {
		return {
			companyEditor:'',
			searchValue:'',
            imgSrc:'',
			productId:'',
			showBackUp: false,
			productImgList:[],
			productList: [],
			activeIndex: 0,
			ProductActiveIndex: 0,
			navList: [
				{ title: "Home", url: "/", showFlag:true },
				{ title: "Products", url: "/jituangaikuo", showFlag:true },
				// { title: "editNet", url: "/editDetail", showFlag:true },
				{ title: "Contacts", url: "/lianxi", showFlag:true }
			]
		};
	},
	mounted(){
		this.windowAddMouseWheel()
		this.ajaxLoad()
	},
	methods: {
		keyUpSearch(val){
			this.$router.push({
				path:'/jituangaikuo',
				query:{
					title:val.target.value
				}
			})
		},
		ajaxLoad(){
			// 获取简介内容
			getDictById().then(res=>{
                if (res.code==200) {
                    this.companyEditor=res.data&&res.data.remark
                }
            })
			// 获取首页简介的内容
			getDictKeyValue({dictId: "2"}).then(res=>{
                    if (res.code==200) {
                        this.imgSrc = res.data&&res.data.length?`http://116.198.202.143:10086/picture/download/${res.data[0].moduleValue}`:''
                    }
                })
            // 获取产品的内容
            getDictKeyValue({dictId: "1"}).then(res=>{
                if (res.code==200) {
                    this.productList = res.data || []
                    this.productId = res.data&&res.data.length&&res.data[0].id || ''
					this._getPage()
                }else{
					this.$message.error(res.msg)
				}
            })
		},
		// 切换产品列表
        changeProduct(index,id){
            this.ProductActiveIndex=index
            if (this.productId == id) {
                return
            }else{
                this.productId = id
                this._getPage()
            }
        },
		_getPage(){
			const params = {
                currPage: 1,
                pageSize: 8,
                type: this.productId
            }
            getPage(params).then(res=>{
                if (res.code == 200) {
                    this.productImgList = res.data.rows || []
					this.productImgList.length&&this.productImgList.forEach(item => {
						this.$set(item,'showHover',false)
					});
					console.log(this.productImgList);
                }
            })
		},
		backToTop(){
			// window.scrollTo(0,0)
			let myTimer = -1
			let scrollTo = document.documentElement.scrollTop || document.body.scrollTop
			if(myTimer == -1){
				myTimer = setInterval(() => {
					scrollTo -= 60
					if(scrollTo<=0){
						scrollTo = 0
						window.clearInterval(myTimer)
						myTimer = -1
					}
					
					window.scrollTo(0,scrollTo) //这是值  是指离开网页顶部的距离   
				}, 20);
				if (document.querySelector('.showBackUp')) {
					document.querySelector('.showBackUp').style.display='none'
				}
			}
		},
		windowAddMouseWheel() {
			var scrollFunc = function (e) {
				e = e || window.event;
				if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
					if (e.wheelDelta > 0) { 
						//滑轮向上滚动1
						this.showBackUp = false
						if (document.querySelector('.showBackUp')) {
							document.querySelector('.showBackUp').style.display='none'
						}
						console.log("滑轮向上滚动1",this.showBackUp);
					}
					if (e.wheelDelta < 0) { 
						//滑轮向下滚动1
						this.showBackUp = true
						if (document.querySelector('.showBackUp')) {
							document.querySelector('.showBackUp').style.display='block'
						}
						console.log("滑轮向下滚动1",this.showBackUp);
					}
				} else if (e.detail) {  //Firefox滑轮事件
					if (e.detail> 0) {
						//滑轮向上滚动1
						console.log("滑轮向上滚动");
						if (document.querySelector('.showBackUp')) {
							document.querySelector('.showBackUp').style.display='none'
						}
						this.showBackUp = true
					}
					if (e.detail< 0) {
						//滑轮向下滚动1
						if (document.querySelector('.showBackUp')) {
							document.querySelector('.showBackUp').style.display='block'
						}
						console.log("滑轮向下滚动");
					}
				}
			};
						//给页面绑定滑轮滚动事件
			if (document.addEventListener) { //火狐使用DOMMouseScroll绑定
				document.addEventListener('DOMMouseScroll', scrollFunc, false);
			}
							//其他浏览器直接绑定滚动事件
			window.onmousewheel = document.onmousewheel = scrollFunc;
		},
		toProduct(){
			this.$router.push({
				path:'/jituangaikuo',
				query:{
					index:this.ProductActiveIndex
				}
			});
		},
		funyuming(msg,index) {
			if (this.activeIndex===index) {
				return
			}
			this.activeIndex = index
			this.$router.push(msg);
		}
	},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
	margin: 0;
	padding: 0;
}
/*去内外边距*/
html,
body {
	font-family: "微软雅黑", "宋体", Arail, Tabhoma;
	text-align: left;
}
/*字体 文本居左*/
ul {
	list-style: none;
}
/*去ul li 小点*/
img {
	border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
	outline: 0;
}
/*去除点击蓝框*/
textarea {
	resize: none;
	overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
	font-weight: normal;
	font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
	text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}
/*清浮动*/
a {
	color: #000000;
}
/*==========================Reset_End===========================*/

.BannerSubTitle{
	display: flex;
	justify-content: center;
}
.BannerSubTitle span{
	background-color: #fff;
	border: 1px solid #333;
	border-radius: 17px;
	padding: 5px 10px;
	color: #000000;
}
.companyIntroduce{
	width: 90%;
	margin: 30px auto;
	border-radius: 8px;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.companyIntroduce .company{
	display: flex;
	padding: 10px;

}
.companyIntroduce .company .imgshow{
	margin-right: 10px;
}
 .companyText .topText{
	margin-bottom: 10px;
}
.showBackUp{
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 20px;
	right: 10px;
	cursor: pointer;
}
.homepage_wrap {
	width: 100vw;
	margin: 0 auto;
	height: 1000px;
	margin-top: 70px;
}

.banner_nav {
	width: 100vw;
	position: relative;
}

.banner_nav_bg img {
	/* 文本居顶部对齐 */
	vertical-align: top;
	width: 100%;
	height: 800px;
}

/* 导航部分 */

.navWrap {
	/* transition: all .3s linear; */
	width: 100%;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	color: #fff;
}

.navWrap_center {
	width: 100%;
	height: 70px;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.navWrap_center_img {
	/* width: 181px; */
	display: flex;
	align-items: center;
	height: 54px;
}

.navWrap_center_img img {
	width: 180px;
	height: 40px;
}


.navWrap_center_ul{
	padding-right: 20px;
}
.navWrap_center_ul li {
	display: inline-block;
	margin-left: 50px;
	padding: 10px 5px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}
.activeLi{
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.navWrap_center_ul li:hover {
	cursor: pointer;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}

/* title */

.banner_title_wrap {
	width: 100%;
	position: absolute;
	/* bottom: 290px; */
	top: 0;
	/* border: 1px solid yellow; */
}

.banner_title_center {
	/* border: 1px solid white; */
	text-align: center;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	margin-top: 150px;
	font-size: 27px;
	font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
	font-weight: bold;
	color: #ffffff;
	letter-spacing: 1px;
}

.banner_title_center div {
	padding-top: 14px;
	font-size: 20px;
	font-family: Helvetica;
	line-height: 24px;
	letter-spacing: 4px;
}

.banner_title_center div span {
	margin: 0 18px;
}
/* 证书资质 */
.certificate{
	display: flex;
	align-items: center;
	height: 340px;
	width: 90%;
	margin: 20px auto;
	display: flex;
	justify-content: center;
}
.certificate img{
	width: 180px;
	height: 300px;
	margin-left: 30px;
}
/* 公司环境 */
.environment{
	display: flex;
	align-items: center;
	width: 90%;
	margin: 10px auto;
	border-radius: 8px;
}
.environment img{
	width: 200px;
	height: 200px;
}
.environment .leftImg{
	overflow-x: auto;
	width: 100%;
	height: 440px;
	display: flex;
	align-items: center;
	/* border: 2px solid rgb(137, 217, 97); */
	padding: 10px;
	padding-right: 0;
	padding-bottom: 0;
	border-radius: 8px;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.3);
	
}
.environment .leftImg .bigImg{
	margin-right: 10px;
	margin-bottom: 10px;
	width: 33%;
}
.environment .leftImg .bigImg img{
	height: 410px;
	width: 100%;
}
.environment .leftImg .smallImg{
	display: flex;
	flex-wrap: wrap;
	width: 66%;
	/* justify-content: space-between; */
}
.environment .leftImg .smallImg img{
	width: 470px;
	margin-right: 10px;
	margin-bottom: 10px;
}
/* 我的产品 */
.GroupIndustry {
	width: 100vw;
	background: #ffffff;
}
.GroupIndustry .top{
	width: 90%;
	margin: 30px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.GroupIndustry_cont {
	width: 90%;
	margin: 0 auto;
}
.productImgClass{
	width: 100%;
	height: 350px;
	border:2px solid #eee;
	border-radius: 8px;
}
.productImgClass:hover{
	box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.2);
}
.productContent{
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	margin-top: 20px;
}
.productImgHover{
	border-radius: 8px;
	margin: 10px 20px 0 0;
	position: relative;
	width: 350px;
	/* display: flex; */
	/* flex-wrap: wrap; */
}
.hoverImgContent{
	position: absolute;
    background: rgba(39, 39, 39, 0.7);
    bottom: 5px;
    width: calc(100% - 2px);
    height: 50px;
    display: flex;
    font-size: 14px;
    margin: 0px 3px;
    border-radius: 0 0 4px 4px;
    justify-content: space-around;
    align-items: center;
	cursor: pointer;
	color: rgb(99, 143, 78);
}
.ProductLiTitle{
	font-size: 18px;
	cursor: pointer;
	padding-bottom: 10px;
}
.ProductLiTitleActive {
	font-weight: bold;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.ProductLiTitle:hover {
	cursor: pointer;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}

</style>
