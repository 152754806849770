<template>
	<div class="youjiaopage_wrap">
		<!-- banner图和导航栏 -->
		<div class="banner_nav">
			<!-- 导航栏 -->
			<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img style="width: 80px;" src="../assets/img/productImg/logoPic.png" alt="" />
						<img src="../assets/img/productImg/logoContent.png" alt="" />
					</div>

					<ul class="navWrap_center_ul">
						<li
							v-for="(item, index) in navList"
							:key="index"
							@click="funyuming(item.url,index)"
							:class="index===activeIndex?'activeLi':''"
						>
							{{ item.title }}
						</li>
						<li>
							<el-input v-model="searchValue" placeholder="请输入产品名称"  @keyup.enter.native="keyUpSearch"></el-input>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="concatBg">
			<div class="concatContent">
				<!-- <p>
					<img src="../assets/img/productImg/company.png" alt="" />
					<span>Guangzhou Sunny Auto Parts Co., Ltd</span>
				</p> -->
				<p>
					<img src="../assets/img/productImg/company.png" alt="" />
					<span>Guangzhou Sunny Auto Parts Co., Ltd</span>
				</p>
				<p>
					<img src="../assets/img/productImg/location.png" alt="" />
					<span>No. 41 Lianquan Road, Yuexiu District, Guangzhou, China 510030</span>
				</p>
				<p>
					<img src="../assets/img/productImg/message.png" alt="" />
					<span>business@gzsap-cn.com, bgliao@126.com</span>
				</p>
				<p>
					<img src="../assets/img/productImg/phone.png" alt="" />
					<span>+86-20-8981 5401 / +86-139 2234 8858</span>
				</p>
				<p>
					<img src="../assets/img/productImg/internet.png" alt="" />
					<span>www.gzsap-cn.com</span>
				</p>
			</div>
			<div class="concatImg">
				<div class="imgItem">
					<img src="../assets/img/wechat.png" alt="" />
					<span>wechat</span>
				</div>
				<div class="imgItem">
					<img src="../assets/img/whatsApp.png" alt="" />
					<span>whatsApp</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "lianxiwomen",
	data() {
		return {
			searchValue:'',
			activeIndex: 3,
			navList: [
				{ title: "Home", url: "/", showFlag:true },
				{ title: "Products", url: "/jituangaikuo", showFlag:true },
				// { title: "editNet", url: "/editDetail", showFlag:true },
				{ title: "Contacts", url: "/lianxi", showFlag:true }
			]
		};
	},
	methods: {
		keyUpSearch(val){
			this.$router.push({
				path:'/jituangaikuo',
				query:{
					title:val.target.value
				}
			})
		},
		funyuming(msg,index) {
			if (this.activeIndex===index) {
				return
			}
			this.activeIndex =  index
			this.$router.push(msg);
		}
	},
};
</script>
<style scoped>
.concatBg {
	margin-top: 70px;
	color:#333;
	height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	background-image:url('../assets/img/productImg/concatBg.jpg');
	background-size: 100vw calc(100vh - 70px);
}
.concatImg {
	flex: 1;
	display: flex;
	margin-right: 50px;
    justify-content: flex-end;
}
.concatImg img {
	width: 100px;
	height: 100px;
}
.imgItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 40px;
}
.concatContent {
	padding-top: 16%;
	margin-left: 20%;
}
.concatContent p {
	line-height: 50px;
	display: flex;
	align-items: center;
}
.concatContent span {
	margin-left: 10px;
}
.concatContent img{
	width: 30px;
	height: 30px;
}
/* 导航部分 */
.navWrap {
	/* transition: all .3s linear; */
	width: 100%;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	color: #fff;
}

.navWrap_center {
	width: 100%;
	height: 70px;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.navWrap_center_img {
	/* width: 181px; */
	display: flex;
	align-items: center;
	height: 54px;
}

.navWrap_center_img img {
	width: 180px;
	height: 40px;
}


.navWrap_center_ul{
	padding-right: 20px;
}
.navWrap_center_ul li {
	display: inline-block;
	margin-left: 50px;
	padding: 10px 5px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}
.activeLi{
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.navWrap_center_ul li:hover {
	cursor: pointer;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
</style>