import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import jituangaikuo from "../views/jituangaikuo.vue";
import editDetail from "../views/editDetail.vue";
import lianxi from "../views/lianxiwomen.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "Home",
		},
	},
	{
		path: "/jituangaikuo",
		name: "jituangaikuo",
		component: jituangaikuo,
		meta: {
			title: "Products",
		},
	},
	{
		path: "/editDetail",
		name: "editDetail",
		component: editDetail,
		meta: {
			title: "editNet",
		},
	},
	{
		path: "/lianxi",
		name: "lianxi",
		component: lianxi,
		meta: {
			title: "Contacts",
		},
	},
	
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
