<template>
  <div class="GroupOverview">
    <!-- banner图和导航栏 -->
	<div class="banner_nav">
		<!-- 导航栏 -->
		<div class="navWrap">
				<div class="navWrap_center">
					<div class="navWrap_center_img">
						<img style="width: 80px;" src="../assets/img/productImg/logoPic.png" alt="" />
						<img src="../assets/img/productImg/logoContent.png" alt="" />
					</div>

					<ul class="navWrap_center_ul">
						<li
							v-for="(item, index) in navList"
							:key="index"
							@click="funyuming(item.url,index)"
							:class="index===activeIndex?'activeLi':''"
						>
							{{ item.title }}
						</li>
						<li>
							<el-input v-model="searchValue" placeholder="请输入产品名称"  @keyup.enter.native="keyUpSearch"></el-input>
						</li>
					</ul>
				</div>
		</div>
        <div class="login" v-if="showLogin">
            <div class="loginDiv"> 账号：<input class="inputClass"  type="text" placeholder="请输入账号"  id="username"></div>
            <div class="loginDiv">密码：<input  class="inputClass" type="password"  placeholder="请输入密码"  id="password"></div>
            <div class="submit" @click="login">登录</div>
            <div id="message" style="color: red;font-size: 12px;"></div>
        </div>
		<div class="GroupIndustry" v-else>
            <div class="editListClass">
                <span v-for="(item,index) in editList" :key="index" @click="changeTab(index)"  :class="index===editIndex?'editListActive editListTitle':'editListTitle'">{{ item }}</span>
            </div>
            <!-- 关于我们 -->
            <div class="aboutUs" v-if="editIndex===0">
                <div style="display: flex;align-items: center;margin: 10px 0;">
                    <span style="width: 100px;">公司简介：</span>
                    <div ref="editor"> </div>
                    <!-- <el-input
                        type="textarea"
                        cols="200"
                        rows="5"
                        placeholder="请输入内容"
                        v-model="companyContent">
                    </el-input> -->
                <!-- <textarea class="textClass" cols="200" rows="5" autosize @input="inputCompany" :vaule="companyContent"></textarea> -->
                </div>
                上传图片：
                <input v-if="!showImg" type="file" id="image" name="image" accept="image/*" @change="changeAboutUsImg">
                <img style="width: 100px;height: 100px;;" v-if="showImg" :src="imgSrc" alt="">
                <span v-if="showImg" style="font-size: 12px; padding-left: 10px;cursor: pointer;" @click="newUpload">
                    <input style="display: none" type="file" id="newImage" name="newImage" accept="image/*" @change="changeAboutUsImg">
                    重新上传
                </span>
                <div class="submit" @click="submitCompany">提交</div>
            </div>
            <!-- 我的产品 -->
			<div class="GroupIndustry_cont" v-if="editIndex===1">
				<ul style="width:100%;display: flex;justify-content: space-around;">
					<li class="ProductLiTitle" @click="changeProduct(index,item.id)" :class="index===ProductActiveIndex?'ProductLiTitleActive':''" v-for="(item,index) in productList" :key="index">{{ item.moduleValue }}</li>
				</ul>
                <!-- <div  >新增产品</div> -->
                <el-button class="addProduct" @click="_addProduct">新增产品</el-button>
                
                <el-table border :data="tableData" style="width: 100%;margin-bottom: 10px;">
                    <el-table-column
                        type="index"
                        width="50"
                        label="序号">
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="名称">
                    </el-table-column>
                    <el-table-column
                        prop="productCode"
                        label="产品编码">
                    </el-table-column>
                    <el-table-column
                        prop="originalCode"
                        label="原厂编码">
                    </el-table-column>
                    <el-table-column
                        label="图片">
                        <template slot-scope="scope">
                            <img style="height: 40px;width: 40px;" :src="`http://116.198.202.143:10086/picture/download/${scope.row.imgName}`" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" align="center">
                        <template slot-scope="scope">
                            <el-Button @click="edit(scope.row)" type="text">编辑</el-Button>
                            <el-Button @click="view(scope.row)" type="text">查看</el-Button>
                            <el-Button @click="del(scope.row)" type="text">删除</el-Button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currPage"
                    :page-size="pageSize"
                    layout="total, prev, pager, jumper, next"
                    :total="total">
                </el-pagination>
            </div>
            <!-- 登录 -->
            <div class="login" v-if="editIndex===3">
                <div class="loginDiv">原密码：<input  class="inputClass" type="password"  placeholder="请输入密码"  id="oldPassword"></div>
                <div class="loginDiv">新密码：<input  class="inputClass" type="password"  placeholder="请输入密码"  id="newPassword"></div>
                <div class="loginDiv">确认新密码：<input  class="inputClass" type="password"  placeholder="请输入密码"  id="repeatPassword"></div>
                <div class="submit" @click="setPassword">修改密码</div>
            </div>
            <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogVisible"
                width="40%">
                <div>
                    <div style="display: flex;align-items: center;margin-bottom: 10px;">
                        <span style="width:90px;text-align: right;">名称：</span>
                        <span v-if="viewFlag">{{ productTitle }}</span>
                        <el-input v-else v-model="productTitle" placeholder="请输入名称"></el-input>
                    </div>
                    <div style="display: flex;align-items: center;margin-bottom: 10px;">
                        <span style="width: 90px;text-align: right;">产品编码：</span>
                        <span v-if="viewFlag">{{ productCode }}</span>
                        <el-input v-else v-model="productCode" placeholder="请输入编码"></el-input>
                    </div>
                    <div style="display: flex;align-items: center;margin-bottom: 10px;">
                        <span style="width: 90px;text-align: right;">原厂编码：</span>
                        <span v-if="viewFlag">{{ originalCode }}</span>
                        <el-input v-else v-model="originalCode" placeholder="请输入编码"></el-input>
                    </div>
                    <span style="padding-left: 45px;">图片：</span>
                    <input v-if="!showProductImg" type="file" id="image2" name="image2" accept="image/*" @change="changeAboutUsImg2">
                    <img style="width: 100px;height: 100px;" v-if="showProductImg" :src="productImgSrc" alt="">
                    <span v-if="showProductImg&&!viewFlag" style="font-size: 12px; padding-left: 10px;cursor: pointer;" @click="newUpload2">
                        <input style="display: none" type="file" id="newImage2" name="newImage2" accept="image/*" @change="changeAboutUsImg2">
                        重新上传
                    </span>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button v-if="!viewFlag" @click="dialogVisible = false">取 消</el-button>
                    <el-button v-if="!viewFlag" type="primary" @click="submitProduct">确 定</el-button>
                    <el-button v-if="viewFlag" type="primary" @click="commitView">确 定</el-button>
                </span>
            </el-dialog>
        </div>
	</div>

    <img v-show="showBackUp" class="showBackUp" @click="backToTop" src="../assets/img/productImg/backUp.jpg" alt="" />
  </div>
</template>

<script>
import E from 'wangeditor'
import { uploadImg, downloadImg, deleteImg,updateProduct,addProduct, deleteProduct, loginIn, logout,getPage, setUserPwd, getDictById,addDictKeyValue, getDictKeyValue,updateDict,deleteDictKeyValue } from "@/api/index";
export default {
    data(){
        return{
			searchValue:'',
            editorContent: '',
            editor: null,
            viewFlag:false,
            dialogTitle:'',
            productCode: '',
            originalCode: '',
            productTitle: '',
            productFileName: '',
            dialogVisible:false,
            pageSize:10,
            currPage:1,
            total:0,
            tableData:[],
            fileName: '',
            companyContent:'',
            imgSrc:'',
            productImgSrc:'',
            imgId:'',
            editFlag:false,
            showProductImg:false,
            showImg:false,
            showLogin:true,
            editList: ['AboutUs','Product','logout','setPassword'],
            editIndex: 0,
            ProductActiveIndex: 0,
            productId:'',
            editId:'',
            showBackUp: false,
            activeIndex: 2,
			productList:  [],
			// productList:  ['Fog Lamp & Cover','Side Mirror','Other Auto Lamp','Fender Liner','Other Parts'],
            navList: [
				{ title: "Home", url: "/", showFlag:true },
				{ title: "Products", url: "/jituangaikuo", showFlag:true },
				{ title: "editNet", url: "/editDetail", showFlag:true },
				{ title: "Contacts", url: "/lianxi", showFlag:true }
			]
        }
    },
    created() {
        this.showLogin = sessionStorage.getItem('jwtToken')?false:true
    },
    mounted(){ 
        this.$nextTick(()=>{
            this.initEditor()
            if (!this.showLogin) {
                this.getAboutUsData()
            }
        })
    },
    methods:{
        initEditor(){
            this.editor = new E(this.$refs.editor)
            this.editor.customConfig = this.editor.customConfig ? this.editor.customConfig : this.editor.config
            this.editor.customConfig.onchange = (html) => {
            this.editorContent = html
            }
            this.editor.customConfig.uploadImgServer = 'xxxxxxxx'  // 上传图片到服务器
            // 隐藏“网络图片”tab
            this.editor.customConfig.showLinkImg = false
            this.editor.create()
        },
		keyUpSearch(val){
            this.$router.push({
				path:'/jituangaikuo',
				query:{
					title:val.target.value
				}
			})
		},
        // 查看产品确定
        commitView(){
            this.viewFlag = false
            this.productTitle=''
            this.productCode=''
            this.productFileName = ''
            this.dialogVisible = false
            this.editFlag = false
        },
        // 查看产品
        view(row){
            this.dialogTitle = '查看'
            this.viewFlag = true
            this.dialogVisible = true
            this.showProductImg = true
            this.productTitle = row.title
            this.productCode = row.productCode
            this.originalCode = row.originalCode
            this.productImgSrc = `http://116.198.202.143:10086/picture/download/${row.imgName}`
            this.productFileName = row.imgName
        },
        // 编辑产品
        edit(row){
            this.dialogTitle = '编辑'
            this.editFlag = true
            this.dialogVisible = true
            this.showProductImg = true
            this.productTitle = row.title
            this.productCode = row.productCode
            this.originalCode = row.originalCode
            this.editId = row.id
            this.productImgSrc = `http://116.198.202.143:10086/picture/download/${row.imgName}`
            this.productFileName = row.imgName
        },
        // 新增,编辑产品保存
        submitProduct(){
            if (this.productTitle=='') {
                this.$message.warning('名称不能为空')
                return
            }
            if (this.productCode=='') {
                this.$message.warning('编码不能为空')
                return
            }
            if (this.productFileName=='') {
                this.$message.warning('图片不能为空或者图片还未上传成功')
                return
            }
            let params ={
                title:this.productTitle,
                productCode:this.productCode,
                originalCode:this.originalCode,
                type:this.productId,
                imgName:this.productFileName
            }
            if (this.editFlag) {
                params.id = this.editId
            }
            const api = this.editFlag?updateProduct:addProduct
            api(params).then(res=>{
                if (res.code==200) {
                    this.$message.success('添加成功')
                    this._getPage()
                }else{
                    this.$message.error(res.msg)
                }
            }).finally(()=>{
                this.productTitle=''
                this.productCode=''
                this.productFileName = ''
                this.productImgSrc = ''
                this.showProductImg = false
                this.dialogVisible = false
                this.editFlag = false
            })

        },
        // 添加产品
        _addProduct(){
            this.dialogTitle = '新增'
            this.dialogVisible = true
        },
        // 切换产品列表
        changeProduct(index,id){
            this.ProductActiveIndex=index
            if (this.productId == id) {
                return
            }else{
                this.productId = id
                this._getPage()
            }
        },
        handleSizeChange(val) {
            this.pageSize = val
            this._getPage()
        },
        handleCurrentChange(val) {
            this.currPage = val
            this._getPage()
        },
        del(row){
            this.$confirm("是否删除该数据?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "error"
                }).then(()=>{
                    deleteProduct(row.id).then(res=>{
                        if (res.code==200) {
                            this.$message.success('删除成功')
                            this._getPage()
                        }
                    })
                })
        },
        // 首页简介输入的值
        inputCompany(event){
            this.companyContent = event.target.value
        },
        // 查询首页图片和文字
        getAboutUsData(){
            getDictById().then(res=>{
                if (res.code==200) {
                    this.editor.txt.html(res.data&&res.data.remark||'');
                }else{
                    this.$message.error(res.msg)
                }
            }).catch(err=>{
                this.$message.error(err.message)
            })
            // 获取首页简介的内容
            getDictKeyValue({dictId: "2"}).then(res=>{
                if (res.code==200) {
                    this.fileName = res.data&&res.data.length?res.data[0].moduleValue:''
                    this.imgSrc = res.data&&res.data.length?`http://116.198.202.143:10086/picture/download/${res.data[0].moduleValue}`:''
                    this.showImg = true
                    this.imgId = res.data&&res.data.length?res.data[0].id:''
                }
            })
            // 获取产品的内容
            getDictKeyValue({dictId: "1"}).then(res=>{
                    if (res.code==200) {
                        this.productList = res.data || []
                        this.productId = res.data&&res.data.length&&res.data[0].id || ''
                    }
                })
        },
        // 提交首页图片和文字
        submitCompany() {
            let flag = true
            // 提交aboutus的文字
            const data = {
                id:3,
                remark: this.editorContent
            }
            updateDict(data).then(res=>{
                if (res.code!=200) {
                    flag = false
                    this.$message.warning(res.msg)
                }
            })
            if (this.imgId!=''&&this.fileName!='') {
                // 先删除原来的图片然后再新增一张新图片
                deleteDictKeyValue(this.imgId).then(res=>{
                    if (res.code==200) {
                        addDictKeyValue({dictId:2,moduleKey:this.fileName,moduleValue:this.fileName}).then(result=>{
                            if (result.code!=200) {
                                flag= false
                            }
                        })
                    }
                }).finally(()=>{
                    if (flag) {
                        this.getAboutUsData()
                        this.$message.success('修改成功')
                        this.$forceUpdate()
                    }
                })
            }else if(this.imgId==''&&this.fileName!=''){
                addDictKeyValue({dictId:2,moduleKey:this.fileName,moduleValue:this.fileName}).then(result=>{
                    if (result.code!=200) {
                        flag= false
                    }
                }).finally(()=>{
                    if (flag) {
                        this.getAboutUsData()
                        this.$message.success('修改成功')
                        this.$forceUpdate()
                    }
                })
            }
        },
        newUpload(){
            document.getElementById('newImage').click()
        },
        newUpload2(){
            document.getElementById('newImage2').click()
        },
        setPassword() {
            const oldPassword = document.getElementById('oldPassword').value;
            const newPassword = document.getElementById('newPassword').value;
            const repeatPassword = document.getElementById('repeatPassword').value;
            const params = {
                userId:'1',
                oldPassword,
                newPassword,
                repeatPassword
            }
            this.editIndex = 0
            setUserPwd(params).then(res=>{
                if (res.code==200) {
                    logout().then(res=>{
                        if (res.code==200) {
                            alert('重新登录')
                            this.showLogin = true
                            sessionStorage.removeItem('jwtToken')
                            sessionStorage.removeItem('jwtToken')
                        }
                    })
                }
            })
        },
        // 获取产品列表
        _getPage(){
            const params = {
                    currPage: this.currPage,
                    pageSize: this.pageSize,
                    type: this.productId
                }
                getPage(params).then(res=>{
                    if (res.code == 200) {
                        this.tableData = res.data.rows || []
                        this.total = res.data.totalCount
                    }
                })
        },
        changeTab(index){
            this.editIndex = index
            if (index===1) {
                this._getPage()
            }
            if (index==2) {
                if (confirm("确定要退出登录吗？")) {
                    this.editIndex = 0
                    logout().then(res=>{
                        if (res.code==200) {
                            this.showLogin = true
                            sessionStorage.removeItem('jwtToken')
                        }
                    })
                }else{
                    this.editIndex = 0
                }
            }
            if (index===0) {
                this.$nextTick(()=>{
                    this.initEditor()
                    if (!this.showLogin) {
                        this.getAboutUsData()
                    }
                })
            }
        },
        login(){
            const username = document.getElementById('username').value
            const password = document.getElementById('password').value
            loginIn({username,password}).then(res=>{
                if (res.code==200&&res.data.accessToken) {
                    sessionStorage.setItem('jwtToken',res.data.accessToken)
                    this.showLogin = false
                    document.getElementById('message').innerHTML=''
                }else{
                    document.getElementById('message').innerHTML='账号密码错误'
                }
            })
        },
        changeAboutUsImg(event){
            const file = event.target.files[0];
            if (file) {
                let fd = new FormData();
                fd.append("file", file);
                // this.showImg = true
                uploadImg(fd).then(res=>{
                    if (res.data&&res.data.flag) {
                        downloadImg(res.data.fileName).then(()=>{
                            if (this.imgSrc!='') {
                                deleteImg({fileNameList:res.data.fileName})
                            }
                            this.imgSrc = `http://116.198.202.143:10086/picture/download/${res.data.fileName}`
                            this.fileName = res.data.fileName
                            this.showImg = true
                        })
                    }
                })
            }
        },
        changeAboutUsImg2(event){
            const file = event.target.files[0];
            if (file) {
                let fd = new FormData();
                fd.append("file", file);
                uploadImg(fd).then(res=>{
                    if (res.data&&res.data.flag) {
                        downloadImg(res.data.fileName).then(()=>{
                            if (this.productImgSrc!='') {
                                deleteImg({fileNameList:res.data.fileName})
                            }
                            this.productImgSrc = `http://116.198.202.143:10086/picture/download/${res.data.fileName}`
                            this.productFileName = res.data.fileName
                            this.showProductImg = true
                        })
                    }
                })
            }
        },
        backToTop(){
			// window.scrollTo(0,0)
			let myTimer = -1
			let scrollTo = document.documentElement.scrollTop || document.body.scrollTop
			if(myTimer == -1){
				myTimer = setInterval(() => {
					scrollTo -= 60
					if(scrollTo<=0){
						scrollTo = 0
						window.clearInterval(myTimer)
						myTimer = -1
					}
					
					window.scrollTo(0,scrollTo) //这是值  是指离开网页顶部的距离   
				}, 20);
				if (document.querySelector('.showBackUp')) {
					document.querySelector('.showBackUp').style.display='none'
				}
			}
		},
        funyuming(msg,index) {
			if (this.activeIndex===index) {
				return
			}
			this.activeIndex = index
			this.$router.push(msg);
		}
    }
}
</script>

<style scoped>
@charset "utf-8";
.loginDiv{
    text-align: center;
    height: 80px;
    line-height: 80px;
}
.login{
    width: 80%;
    margin: 70px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.submit{
    margin-top: 20px;
    width: 72px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border: solid 1px #89d961;
    border-radius: 4px;
    color: #89d961;
}
ul,li{
	margin: 0;
	padding: 0;
}
.ProductLiTitle{
	font-size: 16px;
	cursor: pointer;
	padding-bottom: 10px;
}
.editListTitle{
    display: inline-block;
    min-width: 110px;
	font-size: 18px;
	cursor: pointer;
    padding: 10px 0;
    text-align: center;
    border: 1px solid #999;
}
.editListActive {
	font-weight: bold;
	color: #89d961;
}
.ProductLiTitleActive {
	font-weight: bold;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.editListTitle:hover {
	cursor: pointer;
	color: #89d961;
	/* border-bottom: 2px solid #89d961; */
}
.ProductLiTitle:hover {
	cursor: pointer;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.GroupOverview {
	width: 100vw;
	margin: 0 auto;
	margin-top: 70px;
}
.GroupIndustry {
	width: 100vw;
	background: #ffffff;
}
.editListClass {
	width: 90%;
	margin-top: 90px;
    margin-left: 30px;
}
.aboutUs {
	width: 90%;
	margin: 0 auto;
    margin-top: 40px;
}
.textClass{
    width: 100%;
    margin: 10px;
    padding: 10px;
    font-size: 14px;
    font-family: auto;
}
.inputClass{
    height: 40px;
    line-height: 40px;
}
.textClass:focus-visible {
    outline: 0;
}
.inputClass:focus-visible {
    outline: 0;
}
.GroupIndustry_cont {
	width: 90%;
	margin: 0 auto;
    margin-top: 40px;
}
.GroupIndustry_cont .addProduct{
    margin: 10px 0;
}
.showBackUp{
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 20px;
	right: 10px;
	cursor: pointer;
}
.navWrap {
	/* transition: all .3s linear; */
	width: 100%;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	color: #fff;
}

.navWrap_center {
	width: 100%;
	height: 70px;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.navWrap_center_img {
	/* width: 181px; */
	display: flex;
	align-items: center;
	height: 54px;
}

.navWrap_center_img img {
	width: 180px;
	height: 40px;
}


.navWrap_center_ul{
	padding-right: 20px;
}
.navWrap_center_ul li {
	display: inline-block;
	margin-left: 50px;
	padding: 10px 5px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
}
.activeLi{
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
.navWrap_center_ul li:hover {
	cursor: pointer;
	color: #89d961;
	border-bottom: 2px solid #89d961;
}
</style>