<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>

<style>
body {
  min-width: 1280px;
  overflow-x: auto;
  margin: 0;
}
</style>
